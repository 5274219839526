export default class State {
  constructor({ actions = {}, subscriptions = [], history = [] }) {
    this.actions = actions;
    this.subscriptions = subscriptions;
    this.history = history;
  }

  // Subscribe to an action with a callback function
  subscribe(action, callback) {
    const actions = action.split(",");

    actions.forEach((curAction) => {
      const myAction = curAction.trim();
      // Initialize the subscriptions array for the current action
      this.subscriptions[myAction] = this.subscriptions[myAction] || [];
      // Add the callback function to the subscriptions array
      this.subscriptions[myAction].push((data) => {
        callback.apply(this, data, myAction);
      });
    });
  }

  // Dispatch an action with data
  dispatch(action, data, proxyAction, preserve = false) {
    data = data || [];

    // Preserve the action and data in the history if requested
    if (preserve) {
      this.history.push([action, data]);
    }

    // Call the action's reducer if it exists
    if (typeof this[action] === "function") {
      this[action]({ payload: data });
    }

    // Create an array with the action and data
    const actionData = [action, data];

    // Call the subscribed callbacks for the action or proxy action
    const actionSubscriptions = this.subscriptions[proxyAction || action] || [];
    actionSubscriptions.forEach((subscription) => {
      subscription(actionData);
    });
  }
}
